import { useState } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBBtn } from 'mdb-react-ui-kit';
import LogoImg from '../img/Black-Logo-JT.png';
import { IoLogoWhatsapp } from 'react-icons/io';

function NavBar() {


  const [showNavRight, setShowNavRight] = useState(false);  
  
  return (
    <>
    <MDBNavbar expand='lg' sticky style={{backgroundColor: '#fbfbfb', borderBottom: 'solid 1px #dedede', fontFamily: 'Gotham Light, sans-serif'}} className="shadow-0">
      <MDBContainer style={{maxWidth: '94%'}}>
        <MDBNavbarBrand href='#'>
            <img src={LogoImg} height='35' alt='Logo JT' loading='lazy'/>
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type='button'
          data-target='#navbarRightAlignExample'
          aria-controls='navbarRightAlignExample'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNavRight(!showNavRight)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavRight}>
          <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
            <MDBNavbarItem>
              <Link to='/'>
                <span style={{fontWeight: 600, marginRight: '1rem', color: 'initial'}}>
                  &nbsp; Início
                </span>
              </Link>
            </MDBNavbarItem>  
            <MDBNavbarItem>
              <a href='/#empreendimentos' style={{border: 'none'}}>
                <span style={{fontWeight: 600, marginRight: '1rem', color: 'initial'}}>
                  &nbsp; Empreendimentos
                </span>
              </a>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to='/nossa-historia' style={{border: 'none'}}>
                <span style={{fontWeight: 600, marginRight: '1rem', color: 'initial'}}>
                  &nbsp; Nossa História
                </span>
              </Link>
            </MDBNavbarItem>          
            <MDBNavbarItem>
              <a href='/#faleconosco' style={{border: 'none'}}>
                <span style={{fontWeight: 600, color: 'initial'}}>
                  &nbsp; Fale Conosco
                </span>
              </a>
            </MDBNavbarItem>     
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>      
    </MDBNavbar>

    </>
  );
}

export default NavBar;
