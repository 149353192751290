import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import LOGO from './img/logo-fundo-branco.jpg';
import Index from './screen';
import Empreendimento from './screen/empreendimento';
import SobreNos from './screen/sobre-nos';
import Dashboard from './screen/dashboard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Index />} />
        <Route path="/empreendimento/:slug_id" element={<Empreendimento />} />
        <Route path="/nossa-historia" element={<SobreNos />}/>
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <FloatingWhatsApp phoneNumber={'+5514996028016'} avatar={LOGO} placeholder={'Digite uma mensagem...'} statusMessage={'Geralmente responde rápido'} accountName={'JT Empreendimentos'} chatMessage={'Olá, tudo bem? 🤝 \nPrecisa de ajuda?'} message={'Olá, vim através do site!'} />
    </BrowserRouter>
  );
}

export default App;
