import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import { RegisterLoader } from "./preloader";
import { GalleryUpdate } from "./gallery_update";
import '../index.css';
import { GoVerified } from 'react-icons/go';
import { AiOutlinePicLeft } from 'react-icons/ai';
import { BsChevronRight, BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { CgTrees } from 'react-icons/cg';
import { GiCrane } from 'react-icons/gi';
import { FaTheaterMasks } from 'react-icons/fa';
import { MdOutlineShareLocation, MdOutlineSupportAgent, MdOtherHouses, MdLocalPolice, MdPhoneCallback } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoImage } from 'react-icons/io5';
import { SiGooglestreetview } from 'react-icons/si';


function EmpreendimentoRegister () {

    const api = 'https://api.jtloteadora.com.br';

    const [step, setStep] = useState('introducao');
    const [error, setError] = useState('');
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');
    const [valorParcelas, setValorParcelas] = useState('');
    const [descricao, setDescricao] = useState('');
    const [infraestrutura, setInfraestrutura] = useState('');
    const [seguranca, setSeguranca] = useState('');
    const [lazer, setLazer] = useState('');
    const [sustentabilidade, setSustentabilidade] = useState('');
    const [lotesTotais, setLotesTotais] = useState('');
    const [lotesVendidos, setLotesVendidos] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [mapsLink, setMapsLink] = useState('');

    const register = async () => {

        setStep('send-api');

        let headersList = {
            mode: 'cors', 
        }
        
        let bodyContent = JSON.stringify({"nome": nome, "cidade": cidade+'/'+uf, 
        "descricao": descricao, "infraestrutura": infraestrutura, 
        "seguranca" : seguranca, "lazer" : lazer, 
        "sustentabilidade" : sustentabilidade, "lotes_totais" : lotesTotais, "lotes_vendidos" : lotesVendidos, "valor_parcelas" : valorParcelas, 
        "slug" : nome.replace(/ /g, "-"), "youtube_link" : youtubeLink, 
        "maps_link" : mapsLink});

        let response = await fetch(`${api}/createempreendimento`, { 
            method: "POST",
            body: bodyContent,
            headersList
        });
        
        let data = await response.json();
        
        if(data.status){
            setId(data.id_empreendimento);
            setStep('fotos');
        }else{
            setError(true);
        }        
    }

   useEffect(()=> {
        document.title = 'Cadastrar Empreendimento | JT Loteadora e Incorporadora';
    },[]);



    return(
        <>
                <MDBContainer>                
                    <MDBRow className="justify-content-center" style={{paddingBottom: '5rem'}}>
                        <MDBCol md={10} className='text-center'>
                            <h4 style={{borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Cadastro de Empreendimento</h4>
                            <p style={{fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Vamos lá? Basta seguir as instruções para fazer o cadastro do novo empreendimento.</p>
                        </MDBCol>                    
                    {step === 'introducao' ?
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Primeiro vamos identificar o empreendimento</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Informe abaixo o nome, cidade e estado do empreendimento.</p>
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Digite o nome do empreendimento <b>*</b></label>
                                    <input placeholder="Nome do empreendimento" defaultValue={nome} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setNome(e.target.value)}/>
                                     
                                    <MDBRow>
                                        <MDBCol md={12}>
                                            <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Digite a cidade e estado do empreendimento <b>*</b></label>
                                        </MDBCol>
                                        <MDBCol size={8}>
                                            <input placeholder="Cidade" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                                width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={cidade} onChange={e => setCidade(e.target.value)}/>
                                        </MDBCol>
                                        <MDBCol size={4}>
                                            <input placeholder="UF" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                                width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={uf} maxLength={2} onChange={e => setUf(e.target.value)}/>
                                        </MDBCol>
                                    </MDBRow>

                                    <label className="mt-2" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o valor minímo de cada parcela <b>*</b></label>
                                    <input placeholder="Exemplo: 1.500,00" defaultValue={valorParcelas} style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setValorParcelas(e.target.value)}/>


                                    { nome.length > 0 && cidade.length > 0 && uf.length > 0 && valorParcelas.length > 0 ?
                                        <MDBBtn onClick={() => setStep('vantagens')} className="mt-4" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                    : step === 'vantagens' ?    
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Legal, agora nos de alguns detalhes do empreendimento</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Informe abaixo a descrição (introdução) do empreendimento e 
                                    recursos relacionados a infraestrutura, segurança, lazer e sustentabilidade.</p>
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Digite a descrição (introdução) do empreendimento: <b>*</b></label>
                                    <textarea rows={3} placeholder="Apresente o empreendimento para seu cliente..." defaultValue={descricao} className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} onChange={e => setDescricao(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Quais recursos de infraestrutura o empreendimento tem?</label>
                                    <textarea rows={2} placeholder="Exemplo: Ruas Asfaltadas, rede de esgoto completa etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={infraestrutura} onChange={e => setInfraestrutura(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Quais recursos de segurança o empreendimento tem?</label>
                                    <textarea rows={2} placeholder="Exemplo: Portaria 24 horas, próximo a base da polícia etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={seguranca} onChange={e => setSeguranca(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Quais recursos de lazer o empreendimento tem?</label>
                                    <textarea rows={2} placeholder="Exemplo: Próximo a parques, Próximo aos principais pontos de lazer da cidade, salão de festas e etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={lazer} onChange={e => setLazer(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Quais recursos de sustentabilidade o empreendimento tem?</label>
                                    <textarea rows={2} placeholder="Exemplo: Área arborizada, próximo a eco-pontos e etc." className="mt-2" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={sustentabilidade} onChange={e => setSustentabilidade(e.target.value)}/>                                                                                                                                                                                    
                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', color: '#D38039', marginTop: '0.5rem'}}>Atenção: campos deixados em branco não serão exibidos na página do empreendimento.</p>

                                    <MDBBtn className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039'}} outline onClick={() => setStep('introducao')}><BsArrowLeft /> Introdução</MDBBtn>
                                    { descricao.length > 0 ?
                                        <MDBBtn onClick={() => setStep('outros-detalhes')} className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039', float: 'right'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>     
                    : step === 'outros-detalhes' ?    
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Estamos quase lá...</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Informe abaixo o número de lotes totais e vendidos.</p>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}}>Qual o número de lotes totais? <b>*</b></label>
                                    <input placeholder="Lotes totais" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={lotesTotais} onChange={e => setLotesTotais(e.target.value)}/>
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Qual o número de lotes já negociados?</label>
                                    <input placeholder="Lotes vendidos" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={lotesVendidos} onChange={e => setLotesVendidos(e.target.value)}/>                                                          
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Link para YouTube embed</label>
                                    <input placeholder="Exemplo: https://www.youtube-nocookie.com/embed/IOs8t6hN4To" style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={youtubeLink} onChange={e => setYoutubeLink(e.target.value)}/>       
                                    
                                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-2'>Link para o Google Maps</label>
                                    <input placeholder="Exemplo: " style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                    width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} defaultValue={mapsLink} onChange={e => setMapsLink(e.target.value)}/>                     
                                    
                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', color: '#D38039', marginTop: '0.5rem'}}>Atenção: campos deixados em branco não serão exibidos na página do empreendimento.</p>
                                    <MDBBtn className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039'}} outline onClick={() => setStep('vantagens')}><BsArrowLeft /> Vantagens</MDBBtn>
                                    { lotesTotais.length > 0 ?
                                        <MDBBtn onClick={() => register()} className="mt-2" color="light" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem', textTransform: 'initial', borderRadius: '15px', borderColor: '#D38039', color: '#D38039', float: 'right'}} outline>Continuar <BsArrowRight /></MDBBtn>
                                        :
                                        <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.3rem', marginBottom: '-0.7rem', float: 'right'}}>Preencha os campos com "*" para continuar.</p>
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                    : step === 'send-api' ?
                        <MDBCol md={10}>
                            <RegisterLoader />
                        </MDBCol>  
                    : step === 'fotos' ?
                        <MDBCol md={10}>
                            <MDBCard className="shadow-0" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '1.5rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Agora falta apenas um passo...</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Para que seu empreendimento fique visível, envie abaixo as fotos relacionadas a ele.</p>
                                    <GalleryUpdate id={id} />
                                </MDBCardBody>
                            </MDBCard>                            
                        </MDBCol>  
                    :                    
                        null
                    }                                 
                    </MDBRow>
                </MDBContainer>
        </>
    );
}

export default EmpreendimentoRegister;