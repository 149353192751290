import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBInput } from "mdb-react-ui-kit";
import { useState } from "react";
import Select from 'react-select'
import { GalleryUpdate } from "./gallery_update";
import { BsPencilSquare } from 'react-icons/bs';
import { RegisterLoader } from "./preloader";

const api = 'https://api.jtloteadora.com.br';

function EditEmpreendimento({id}){

    const [select, setSelect] = useState(false)
    const [value, setValue] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const update = async () => {

        if(!select && !value && !id){
            return
        }

        setStatusLoading(true);

        let headersList = {
            mode: 'cors', 
            "Content-Type": "application/x-www-form-urlencoded"
        }

        let bodyContent = JSON.stringify({"id": id, "tipo": select.value, 
        "valor": value,});
      
        try{
          
          let response = await fetch(`${api}/updateempreendimento`, { 
              method: "POST",
              body: bodyContent,                      
              headersList
          });

          let data = await response.json();

          if(data.status === true){
            setUpdated(true);
            setStatusLoading(false);
          }else{
            setUpdated('error');
            setStatusLoading(false);
          }

        }catch (e){
          //console.log(e);
          setUpdated('error');
          setStatusLoading(false);
        }
        
    }

    const options = [        
        { value: 'nome', label: 'Nome do empreendimento' },
        { value: 'cidade', label: 'Localização do empreendimento' },
        { value: 'descricao', label: 'Descrição do empreendimento' },
        { value: 'infraestrutura', label: 'Infraestrutura do empreendimento' },
        { value: 'seguranca', label: 'Segurança do empreendimento' },
        { value: 'lazer', label: 'Lazer do empreendimento' },
        { value: 'sustentabilidade', label: 'Sustentabilidade do empreendimento' },
        { value: 'lotes_totais', label: 'Número de lotes do empreendimento' },
        { value: 'lotes_vendidos', label: 'Número de lotes vendidos do empreendimento' },
        { value: 'valor_parcelas', label: 'Valor mínimo de parcelas do empreendimento' },
        { value: 'youtube_link', label: 'Link do YouTube embed' },
        { value: 'maps_link', label: 'Link do Google Maps' },
        { value: 'fotos', label: 'Fotos do empreendimento' },
      ]
      
    return (
        <>
        <MDBContainer>
            <MDBRow className="justify-content-center">
                <MDBCol md={11}>
                <div className="d-flex align-items-center"> 
                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                        <BsPencilSquare size={'2rem'} color='#D38039'/>
                    </div>                               
                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                    <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #D38039', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1.1rem'}}>
                        Editar Empreendimento
                    </span> <br />
                        Altere as informações relacionadas ao empreendimento selecionado.</p>  
                </div>                    
                </MDBCol>
                <MDBCol md={11}>
                    <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem'}} className='mt-4'>Para começar, selecione o que você deseja alterar no empreendimento:</label>
                    <Select className="mt-1" onChange={e => setSelect(e)} options={options} placeholder={'Selecione uma opção'} />
                </MDBCol>
                <MDBCol md={11}>
                    <hr />
                </MDBCol>                
                { select ?
                        <>
                            { select.value !== 'fotos' ?
                                <MDBCol md={11}>
                                    { !statusLoading ?
                                        <>
                                            <label style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '0.2rem',}}>{select.label}</label>
                                            <textarea onChange={e => setValue(e.target.value)} rows={2} placeholder="Digite aqui sua alteração para essa opção..." style={{backgroundColor: '#f5f5f5', borderColor: 'transparent', 
                                                width: '100%', padding: '0.5rem 0.5rem 0.5rem 0.5rem', borderRadius: '10px'}} />
                                            <MDBBtn onClick={() => update()} style={{width:'100%' , marginTop: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>Salvar alteração</MDBBtn>
                                        </>
                                    :
                                        <>
                                        { updated === 'error' ?
                                            null
                                          : updated ?
                                            null
                                          :
                                            <RegisterLoader />
                                        }
                                        </>
                                    }
                                </MDBCol> 
                                :
                                <MDBCol md={12}>
                                    <GalleryUpdate id={id}/>
                                </MDBCol>
                            }
                        </>
                        :
                        null
                } 
            </MDBRow>
        </MDBContainer>
        </>
    );
}

export { EditEmpreendimento }