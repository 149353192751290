import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdb-react-ui-kit';
import { useEffect } from 'react';
import { GoVerified } from 'react-icons/go';
import { RiMedalFill } from 'react-icons/ri';
import { FaPeopleCarry } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import NavBar from '../component/navbar';
import Footer from '../component/footer';
import BannerBackground from '../img/empreendimento_view2.jpeg';
import '../index.css';



function SobreNos(){

    useEffect(() => {
        document.title = "Nossa História | JT Loteadora e Incorporadora";
        window.scrollTo(0, 0);
    }, []);

    return(
    <>
        <NavBar />
        <MDBContainer style={{maxWidth: '90%'}}>
            <MDBRow>
                <MDBCol>
                    <MDBCard className='shadow-0' style={{ marginTop: '2rem', backgroundImage: `url(${BannerBackground})`, borderRadius: '15px', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <MDBCardBody style={{padding: '5rem 1.2rem 6rem 1.2rem', borderRadius: '15px'}} className='home-gradient-background'>
                            <h1 style={{color: '#f2f2f2', fontWeight: 600}}>Entenda como conquistamos <br className='mobile-hidden' />nossa posição e respeito no mercado</h1>
                            <h6 style={{color: '#f2f2f2', marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify'}}>Conheça nosso ideal, nosso valores e nossa história.</h6>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <hr style={{marginTop: '3rem'}} />
                </MDBCol>
            </MDBRow>            
            <MDBRow className='justify-content-center'>
                <MDBCol md={5}>
                    <h3 style={{marginTop: '1.5rem', fontSize: '2.2rem', color: '#2B2F33', fontFamily: 'Gotham, sans-serif', fontWeight: 700, paddingLeft: '0.7rem', borderRadius: '5px', borderLeft: 'solid 0.5rem #D38039'}}>Conheça nosso ideal</h3>
                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify', marginBottom: '2rem', fontSize: '0.9rem', marginTop: '1.5rem'}}>
                        A JT Loteadora representa a junção entre a tradição e o nome de uma família centenária com a inovação e as melhores soluções em termos de loteamento. 
                        Conquistamos a confiança e posição que temos hoje no mercado, baseando todas as nossas ações nos pilares que juntos formam nosso ideal.</p>
                    <div className="d-flex align-items-center" style={{marginBottom: '1.5rem'}}>
                        <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                            <GoVerified size={'1.5rem'} color='#D38039'/>
                        </div>                                
                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}>
                            <span style={{fontWeight: 600, fontFamily: 'Gotham, sans-serif'}}>Missão</span> <br />
                            Promover o bem estar urbano a partir do desenvolvimento de empreendimentos que orgulhem nossos clientes.</p>
                    </div>    
                    <div className="d-flex align-items-center" style={{marginBottom: '1.5rem'}}> 
                        <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                            <FaPeopleCarry size={'1.5rem'} color='#D38039'/>
                        </div>                                                     
                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}>
                            <span style={{fontWeight: 600, fontFamily: 'Gotham, sans-serif'}}>Visão</span> <br />
                            Expandir seus negócios e tornar-se um player de abrangência nacional.</p>
                    </div> 
                    <div className="d-flex align-items-center">
                        <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                            <RiMedalFill size={'1.7rem'} color='#D38039'/>
                        </div>                                
                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}>
                            <span style={{fontWeight: 600, fontFamily: 'Gotham, sans-serif'}}>Valores</span> <br />
                            <ul style={{fontSize: '0.8rem'}}>
                                <li>Satisfação do Cliente - Ele é a razão da existência de qualquer negócio.</li>
                                <li>Valorização e Respeito às Pessoas - São as pessoas o grande diferencial que torna tudo possível.</li>
                                <li>Responsabilidade Social  - É a única forma de crescer em uma sociedade mais justa.</li>
                                <li>Respeito ao Meio Ambiente - É isso que nos dá a perspectiva do amanhã.</li>
                            </ul>
                            </p>
                    </div>                                   
                </MDBCol>
                <MDBCol md={6}>
                    <MDBCard className='shadow-0' style={{backgroundColor: 'transparent', borderRadius: '30px', marginTop: '3rem', width: '100%', height: '85%'}}>
                        <MDBCardBody>
                            <iframe width="100%" height="100%" className='shadow-5' style={{borderRadius: '15px'}} src="https://www.youtube-nocookie.com/embed/IOs8t6hN4To" 
                                title="YouTube video player" frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </MDBCardBody>
                    </MDBCard>                        
                </MDBCol>                    
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <hr style={{marginTop: '1rem'}} />
                </MDBCol>
            </MDBRow>  
            <MDBRow className='justify-content-center'>
                <MDBCol md={11}>
                    <h3 style={{marginTop: '1.5rem', fontSize: '2.2rem', color: '#2B2F33', fontFamily: 'Gotham, sans-serif', fontWeight: 700, paddingLeft: '0.7rem', borderRadius: '5px', borderLeft: 'solid 0.5rem #D38039'}}>Conheça nossa história</h3>
                    <p style={{fontWeight: 600, fontFamily: 'Gotham Light, sans-serif', marginTop: '2rem', fontSize: '0.9rem', textAlign: 'justify'}}>	
                        A família Almeida Tavares está radicada em Santa Cruz do Rio Pardo desde 1904, quando o Sr. Firmino de Almeida Tavares adquire propriedade rural na Água das Palmeiras, para cultivo de alfafa e café. Seu filho José de Almeida Tavares nasceu neste sítio e com 17 anos casa-se com a Sra. Marcília em Assis-SP. <br />
                         <br />
                        Em 1931  adquire seu primeiro sítio na Água da Prata. Seguindo o exemplo de perseverança e trabalho de seu pai Firmino, José trabalha arduamente e torna-se um dos primeiros habitantes de Santa Cruz do Rio Pardo proprietário de caminhões, ramo em que atuou transportando safras para os cerealistas da região e possibilitou auxiliar o desenvolvimento de toda a comunidade. O resultado de todo esse trabalho e empenho foi a compra de duas propriedades, uma na Mandassaia e outra em Três Ilhas.

                        <br /> <br />

                        Neto do Sr. Firmino e filho do Sr. José, Joel de Almeida Tavares, caminhoneiro e empreendedor nato, adquire as cota partes do sítio da Mandassaia de seus irmãos, e deixa a propriedade para seus filhos e esposa. 
                         <br />
                        Nesse cenário de responsabilidade, trabalho e visão, nasce a JT Loteadora. 
                         <br /><br />
                        A JT Loteadora é uma empresa de capital fechado, gerida pela família Almeida Tavares e com a proposta de revolucionar o contexto imobiliário da cidade de Santa Cruz do Rio Pardo. Nosso maior ativo é o nome e a probidade inconteste de toda a família, bem como o DNA empreendedor e visionário. 
                         <br /><br />
                        Todos os projetos desenvolvidos pela JT Loteadora ostentam dos mais altos padrões em termos de loteamentos e incorporações. Essa qualidade é perceptível para todas as pessoas que analisam os nossos empreendimentos: desde as ruas com largura suficiente para transitam em tranquilidade, até os detalhes de fixação e peneira das bocas de lobo. 
                         <br /><br />
                        A JT Loteadora representa a junção entre a tradição e o nome de uma família centenária com a inovação e as melhores soluções em termos de loteamento, tanto para investir quanto para morar.
                    </p>    
                </MDBCol>  
                <MDBCol md={11}>
                    <MDBCard className='shadow-0' style={{ marginTop: '1.5rem', marginBottom: '3rem', borderRadius: '15px', backgroundColor: '#D38039', }}>
                        <MDBCardBody style={{padding: '3rem 1.5rem 3rem 1.5rem', borderRadius: '15px'}}>
                            <MDBRow className='justify-content-center align-content-center'>
                                <MDBCol md={6}>
                                    <h1 style={{color: '#f2f2f2', fontWeight: 600}}>Gostaria de saber mais sobre nós?</h1>
                                </MDBCol>
                                <MDBCol md={6}>
                                    <h6 style={{color: '#f2f2f2', marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify'}}>Ficaremos felizes em contar mais sobre nós ou esclarecer<br className='mobile-hidden'/> qualquer dúvida que você esteja sobre nós.</h6>
                                    <MDBBtn onClick={() => window.location.href = '/#faleconosco'} style={{marginTop: '2rem', borderRadius: '25px', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textTransform: 'initial', fontSize: '1.05rem'}} color='light'><MdOutlineSupportAgent size={'1.5rem'} color='#D38039'/>&nbsp; Fale com a gente</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol> 
            </MDBRow>          
        </MDBContainer>
        <Footer />    
    </>
    );
}

export default SobreNos;