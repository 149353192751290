import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { MDBContainer,MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import { DashboardPreloader } from "../component/preloader";
import { EmpreendimentoDashList } from "../component/empreendimentos-list";
import NavBar from "../component/navbar";
import EmpreendimentoRegister from "../component/emprendimento-register";
import Footer from "../component/footer";
import '../index.css';
import { GoVerified } from 'react-icons/go';
import { AiOutlinePicLeft } from 'react-icons/ai';
import { BsFillCameraVideoFill } from 'react-icons/bs';
import { CgTrees } from 'react-icons/cg';
import { GiCrane } from 'react-icons/gi';
import { FaTheaterMasks } from 'react-icons/fa';
import { MdOutlineShareLocation, MdOutlineSupportAgent, MdOtherHouses, MdLocalPolice, MdPhoneCallback } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoImage } from 'react-icons/io5';
import { SiGooglestreetview } from 'react-icons/si';
import Carousel from "react-multi-carousel";


function Dashboard () {
    const [view, setView] = useState('dashboard');
    const [fotos, setFotos] = useState([]);
    const [galleryType, setGalleryType] = useState('fotos');
    const { slug_id } = useParams();
    let t = '';

    useEffect(()=> {
        /*async () => {
            let headersList = {
                mode: 'cors', 
            }
            
            let response = await fetch("https://api.jtloteadora.com.br/empreendimento/"+slug_id, { 
                method: "GET",
                headersList
            });
            
            let data = await response.json();
            document.title = data.NOME_EMPREENDIMENTO + ' | JT Loteadora e Incorporadora';
            setData(data);
            setFotos(data.FOTOS.split(';').filter(function (i) {
                return i;
              }));
        })();*/
    });


    return(
    <>
        <NavBar />    
            {view === 'dashboard' ?
                <MDBContainer>                
                    <MDBRow className="justify-content-center" style={{paddingBottom: '5rem'}}>
                        <MDBCol md={10}>
                            <h4 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Bem vindo(a) a sua área de gerenciamento, o que deseja fazer agora?</h4>
                            <p style={{fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Escolha uma das opções abaixo para começar.</p>
                        </MDBCol>                    
                        <MDBCol md={5}>
                            <MDBCard onClick={() => setView('adicionar_empreendimento')} className="hover-shadow" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '0.7rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Adicionar novo empreendimento</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Clique aqui para adicionar um novo empreendimento a plataforma.</p>                                    
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>  
                        <MDBCol md={5}>
                            <MDBCard onClick={() => setView('gerenciar_empreendimento')} className="hover-shadow" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '20px', marginTop: '1.3rem'}}>
                                <MDBCardBody style={{background: '#fff', borderRadius: '20px', marginBottom: 0, paddingBottom: '0.7rem'}}>
                                    <h6 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '3px', paddingLeft: '0.7rem', textTransform: 'uppercase', fontFamily: 'Gotham, sans-serif', fontWeight: 600,}}>Gerenciar empreendimentos</h6>
                                    <p style={{ fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Clique aqui para visualizar, editar ou excluir empreendimento cadastrados na plataforma.</p>                                    
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol> 
                    </MDBRow>
                </MDBContainer>
            : view === 'adicionar_empreendimento' ?
                <EmpreendimentoRegister />
            : view === 'gerenciar_empreendimento' ?
                <>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md={10}>
                            <h4 style={{borderLeft: 'solid 0.4rem #D38039', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '3rem', fontWeight: 600}}>Gerenciamento de empreendimentos cadastrados</h4>
                            <p style={{fontSize: '0.9rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600}}>Escolha uma empreendimento para pode visualizar suas opções de gerenciamento.</p>
                        </MDBCol>    
                    </MDBRow>
                </MDBContainer>
                <EmpreendimentoDashList />
                </>
            :
                <DashboardPreloader />
            }

    </>
    );
}

export default Dashboard;