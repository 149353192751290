import { MDBContainer, MDBRow, MDBCol,} from "mdb-react-ui-kit";
import { EmpreendimentosSimpleList } from "./empreendimentos-list";
import LogoImg from '../img/Black-Logo-JT.png';
import '../index.css';

function Footer(){
    return(
        <>
        <div style={{backgroundColor: '#fbfbfb', width: '100%', paddingTop: '2rem', paddingBottom: '3rem', borderTop: 'solid 1px #dcdcdc',}}>
            <MDBContainer style={{width: '100%', color: '#2B2F33'}}>
                <MDBRow className="text-center justify-content-center" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>
                    <MDBCol md={4}>
                        <div style={{fontSize: '0.8rem'}} className='text-center mt-2'>
                            <img src={LogoImg} style={{width: '70%'}} className='img-fluid'/>
                            <p style={{marginTop: '0.7rem', fontSize: '1.2rem', color: '#D38039', fontFamily: 'Gotham Light, sans-serif'}}><i>Projetando o futuro.</i></p>
                            <hr style={{width: 'auto', marginRight: '2.5rem', marginLeft: '2.5rem'}}/>
                            <p>JT - Loteadora e Incorporadora LTDA</p>
                            <p style={{marginTop: '-0.5rem'}}>CNPJ: 20.995.587/0001-23</p>
                        </div>
                    </MDBCol>
                    <MDBCol md={4}>
                        <div className="mt-2">
                            <p style={{fontWeight: 600, fontSize: '1.2rem', fontFamily: 'Gotham, sans-serif'}}>Nossos Empreendimentos</p>
                            <EmpreendimentosSimpleList style={{marginBottom: '2rem'}} />
                        </div>
                    </MDBCol>
                    <MDBCol md={4}>
                        <div className="mt-2">
                            <p style={{fontWeight: 600, fontSize: '1.2rem', fontFamily: 'Gotham, sans-serif'}}>Links Úteis</p>
                            <p><a style={{color: '#2B2F33', fontSize: '0.9rem'}} href="/#empreendimentos">Nossos Empreendimentos</a></p>
                            <p><a style={{color: '#2B2F33', fontSize: '0.9rem'}} href="/#porque-nos-escolher">Porque Nos Escolher</a></p>
                            <p><a style={{color: '#2B2F33', fontSize: '0.9rem'}} href="/nossa-historia">Nossa História</a></p>
                            <p><a style={{color: '#2B2F33', fontSize: '0.9rem'}} href="/#faleconosco">Fale Conosco</a></p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
        <div style={{backgroundColor: '#fbfbfb', width: '100%', borderTop: 'solid 1px #dcdcdc', paddingTop: '1rem'}}>
            <MDBContainer>
                <MDBRow className="justify-content-center" style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.6rem', color: '#2B2F33'}}>
                    <MDBCol md={12} className='text-center'>
                        <p>© 2022 Copyright JT - Loteadora e Incorporadora LTDA - CNPJ: 20.995.587/0001-23. Todos os Direitos Reservados.</p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
        </>
    );
}

export default Footer;