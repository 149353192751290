import { useState, useEffect }  from "react";
import { MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBRow, MDBContainer,  MDBModal, MDBModalDialog, MDBModalContent,
    MDBModalHeader, MDBModalTitle, MDBModalBody, } from 'mdb-react-ui-kit';
import { EmpreendimentosPreloader, EmpreendimentosDashPreLoader, EmpreendimentosSimpleListPreloader, DeleteLoader } from "./preloader";
import { EditEmpreendimento } from "./empreendimento-edit";
import { IoEyeOutline } from 'react-icons/io5';
import { IoIosArrowBack } from 'react-icons/io';
import { BsPencilSquare } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { TbTrashOff } from 'react-icons/tb';
import '../index.css';

const api = "https://api.jtloteadora.com.br";

function EmpreendimentoDashList () {

    const [data, setData] = useState(false);
    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [slug, setSlug] = useState('');
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [optionsStep, setOptionsStep] = useState('intro');
    const [basicModal, setBasicModal] = useState(false);
    
    const toggleShow = () => {
        setBasicModal(!basicModal);
        setOptionsStep('intro');
        if(deleteStatus === 'success'){
            setDeleteStatus(false);
            refreshList();
        }else{
            setDeleteStatus(false);
        }
    }

    const empreendimentoSelect = (id, nome, slug) => {
        setId(id);
        setNome(nome);
        setSlug(slug);
        toggleShow();
    }

    const refreshList = async () => {
        
        setData(false);

        let headersList = {
            mode: 'cors', 
        }
        
        let response = await fetch(api + "/empreendimentos", { 
            method: "GET",
            headersList
        });
        
        let data = await response.json();
        setData(data);
    }

    const deleteEmpreendimento = async () => {

        setDeleteStatus('waiting');

        let headersList = {
            mode: 'cors', 
        }

        let bodyContent = JSON.stringify({"id": id,});

        try{
        
            let response = await fetch(api + "/deleteempreendimento", { 
                method: "POST",
                body: bodyContent,
                headersList
            });
            
            let data = await response.json();
            if(data.status){
                setDeleteStatus('success');
            }else{
                setDeleteStatus('error');
            }

        }catch (e){
            setDeleteStatus('error');
        }
    }

    useEffect(()=> {
        (async () => {
            let headersList = {
                mode: 'cors', 
            }
            
            let response = await fetch(api + "/empreendimentos", { 
                method: "GET",
                headersList
            });
            
            let data = await response.json();
            setData(data);
        })();
    },[]);

    return (
    <>
        { data.empreendimentos ?
            <MDBContainer>
                <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                    <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                        <MDBModalTitle style={{paddingLeft: '0.7rem', borderRadius: '4px', borderLeft: 'solid 0.4rem #D38039',}}>{nome}</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBContainer>
                            { optionsStep === 'intro' ?
                                <MDBRow className="justify-content-center" style={{paddingBottom: '1.6rem'}}>
                                    <MDBCol md={12}>
                                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem'}} className='text-center'>Selecione uma das opções para continuar</p>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => window.open('/empreendimento/'+slug, '_blank')} className="hover-shadow" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <IoEyeOutline size={'2rem'} color='#D38039'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #D38039', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Visualizar Online
                                                        </span> <br />
                                                        Clique para acessar as opções para esse empreendimento.</p>  
                                                </div> 
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => setOptionsStep('editar')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <BsPencilSquare size={'2rem'} color='#D38039'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #D38039', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Editar Empreendimento
                                                        </span> <br />
                                                        Clique para acessar as opções para esse empreendimento.</p>  
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <MDBCard onClick={() => setOptionsStep('deletar')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                            <MDBCardBody>
                                                <div className="d-flex align-items-center"> 
                                                    <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                        <FaRegTrashAlt size={'2rem'} color='#D38039'/>
                                                    </div>                               
                                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                        <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #D38039', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                            Excluir Empreendimento
                                                        </span> <br />
                                                        Clique para acessar as opções para esse empreendimento.</p>  
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            : optionsStep === 'editar' ?
                                <>
                                    <EditEmpreendimento id={id}/>
                                    <MDBRow className="text-center">
                                        <MDBCol>
                                            <MDBBtn onClick={() => setOptionsStep('intro')} color="danger" style={{width:'86.5%' , marginTop: '1rem', marginBottom: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem',}} outline>
                                                Fechar
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            : optionsStep === 'deletar' ?
                                <MDBRow>
                                    { deleteStatus === false ?
                                    <>
                                        <MDBCol md={12}>
                                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '1rem'}} className='text-center'>Continuar com exclusão do empreendimento?</p>
                                        </MDBCol>
                                        <MDBCol md={12}>
                                            <MDBCard onClick={() => deleteEmpreendimento()} className="hover-shadow mt-1" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px',}}>
                                                <MDBCardBody>
                                                    <div className="d-flex align-items-center"> 
                                                        <div className="d-flex align-items-center justify-content-center" style={{ marginRight: '1rem', marginLeft: '0.3rem'}}>
                                                            <FaRegTrashAlt size={'2rem'} color='#D38039'/>
                                                        </div>                               
                                                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                            <span style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #D38039', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                                                Continuar com exclusão
                                                            </span></p>  
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                            <MDBCard onClick={() => setOptionsStep('intro')} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', borderRadius: '25px', padding: '1rem'}}>
                                                    <div>                               
                                                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.7rem', marginLeft: '1rem', marginBottom: 0, paddingBottom: 0}}>
                                                            <span style={{ fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '0.8rem'}}>
                                                            <IoIosArrowBack size={'1.7rem'} color='#D38039'/> Voltar para opções
                                                            </span></p>  
                                                    </div>
                                            </MDBCard>
                                        </MDBCol>
                                    </>
                                    : deleteStatus === 'waiting' ?
                                        <DeleteLoader />
                                    : deleteStatus === 'success' ?
                                        <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                                            <FaRegTrashAlt style={{width: '100px' , height: '100px', color: '#D38039'}} />
                                            <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Tudo Pronto</h4>
                                            <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                <i>O empreendimento foi excluído com sucesso.</i></p>
                                        </MDBCol> 
                                    : deleteStatus === 'error' ?
                                        <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
                                            <TbTrashOff style={{width: '100px' , height: '100px', color: '#D38039'}} />
                                            <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Houve um erro</h4>
                                            <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
                                                <i>Não foi possível excluir o empreendimento. Tente novamente.</i></p>
                                        </MDBCol> 
                                    :
                                        null       
                                    }
                                </MDBRow>
                            :
                                null
                            }
                            </MDBContainer>
                        </MDBModalBody>
                    </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                <MDBRow style={{marginBottom: '3rem'}}>
                {data.empreendimentos.map( (empreendimentos) => 
                    <MDBCol md={6} key={empreendimentos.ID}>
                        <MDBCard onClick={() => empreendimentoSelect(empreendimentos.ID, empreendimentos.NOME_EMPREENDIMENTO, empreendimentos.SLUG)} className="hover-shadow mt-3" style={{border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px',}}>
                            <MDBCardBody>
                                <p style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #D38039', fontFamily: 'Gotham, sans-serif', fontWeight: 600, fontSize: '1rem'}}>
                                    {empreendimentos.NOME_EMPREENDIMENTO}</p>
                                <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem'}}>{empreendimentos.CIDADE_LOCALIZACAO}</p>
                                <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: 0}}>
                                    Clique para acessar as opções para esse empreendimento.</p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>  
                )}
                </MDBRow>
            </MDBContainer>
            :
            <MDBContainer style={{paddingTop: '1rem'}}>
                <EmpreendimentosDashPreLoader />
            </MDBContainer>
        }
    </>
    );
}

function EmpreendimentosSimpleList({style, className}){
    
    const [data, setData] = useState(false);

    useEffect(()=> {
        (async () => {
            let headersList = {
                mode: 'cors', 
            }
            
            let response = await fetch(api + "/empreendimentos", { 
                method: "GET",
                headersList
            });
            
            let data = await response.json();
            setData(data);
        })();
    },[]);

    return(
    <>
        { data.empreendimentos ?
            <div style={style} className={className + ''}>
                {data.empreendimentos.map( (empreendimentos) => 
                    <p key={empreendimentos.ID}>
                        <a style={{color: '#2B2F33', fontSize: '0.9rem'}} target='_blank' href={'/empreendimento/'+empreendimentos.SLUG}>{empreendimentos.NOME_EMPREENDIMENTO}</a>
                    </p>
                )}
            </div>
            :
            <EmpreendimentosSimpleListPreloader style={style} className={className} />
        }        
    </>
    );
}

function Empreendimentos({style, className}){

    const [data, setData] = useState(false);

    useEffect(()=> {
        (async () => {
            let headersList = {
                mode: 'cors', 
            }
            
            let response = await fetch(api + "/empreendimentos", { 
                method: "GET",
                headersList
            });
            
            let data = await response.json();
            setData(data);
        })();
    },[]);

    return (
    <>
        { data.empreendimentos ?
            <div style={style} className={className + ''}>
            {data.empreendimentos.map( (empreendimentos) => 
                <MDBCol md={4} style={{display: 'inline-block'}} key={empreendimentos.ID}>
                    <MDBCard className="shadow-1" style={{whiteSpace:'normal', height: '16rem', border: 'solid 1px #dcdcdc', marginRight: '1.5rem', borderRadius: '15px', background: ('url("'+api+'/images/'+empreendimentos.ID+'/'+(empreendimentos.FOTOS.split(';').filter(function (i) { return i; })[0])+'")'), backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <MDBCardBody style={{background: 'linear-gradient(to right, #D38039CC, #D3803911)', borderRadius: '15px'}}>
                            <p style={{paddingLeft: '0.5rem', borderRadius: '3px', borderLeft: 'solid 0.3rem #f2f2f2', textTransform: 'uppercase', color: '#f2f2f2', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem'}}>Construa ou invista</p>
                            <h3 style={{color: '#f2f2f2'}}>{empreendimentos.NOME_EMPREENDIMENTO}</h3>
                            <MDBBtn onClick={() => window.location.href = '/empreendimento/' + empreendimentos.SLUG} style={{marginTop: '2rem', borderRadius: '25px', top: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textTransform: 'initial', fontSize: '0.85rem'}} color='light'>Quero conhecer</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>  
            )}
            </div>
            :
            <EmpreendimentosPreloader style={style} className={className}/>
        }
    </>
    );
}

export { Empreendimentos, EmpreendimentosSimpleList, EmpreendimentoDashList };