import React, {useCallback, useEffect, useState, useMemo} from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../index.css';
import { GalleryUpdateLoader } from './preloader';
import {useDropzone} from 'react-dropzone';
import { AiFillPicture } from 'react-icons/ai';
import { BsCheck2Circle } from 'react-icons/bs';
import { BiErrorAlt } from 'react-icons/bi';

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    height: '20rem',
    borderWidth: 2,
    borderRadius: 30,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
    
  function GalleryUpdate({id}) {

    const [statusLoading, setStatusLoading] = useState(false);
    const [updated, setUpdated] = useState(false);

    const style = useMemo(() => ({
        ...baseStyle,}), []);
      
    const [files, setFiles] = useState([]);
    const [accept, setAccept] = useState(undefined)
    const empreendimentoID = id;

    const {getRootProps, getInputProps,} = useDropzone({
      accept: {
        'image/*': []
      },
      onDrop: acceptedFiles => {
        setAccept(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });
    
    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />
        </div>
      </div>
    ));

    const uploadImages = async (acceptedFiles) => {

        if(!empreendimentoID){
          return;
        }

        setStatusLoading(true);

        let headersList = {
            mode: 'cors', 
            "Content-Type": "application/x-www-form-urlencoded"
        }

        let bodyContent = new FormData();

        for (const file of acceptedFiles) {
            bodyContent.append('fotos[]', file, file.name);
        }
        bodyContent.append('id', empreendimentoID);
      
        try{
          let response = await fetch("https://api.jtloteadora.com.br/updategallery", { 
              method: "POST",
              body: bodyContent,  
                    
              headersList
          });
          let data = await response.json();

          if(data.status === true){
            setUpdated(true);
            setStatusLoading(false);
          }else{
            setUpdated('error');
            setStatusLoading(false);
          }
        }catch (e){
          //console.log(e);
          setUpdated('error');
          setStatusLoading(false);
        }
        
    }

  
    useEffect(() => {
      return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
  
    return (
      <>
      { statusLoading ?

        <GalleryUpdateLoader />

      : 
      <>
      { !updated ? 
      <section className="container">
        <div {...getRootProps({style, className: 'dropzone'})}>
          <input {...getInputProps()} />
          <h4 style={{marginTop: '15%'}} className='text-center'><AiFillPicture /> Clique ou solte as fotos do empreendimento aqui...</h4>
        </div>
        <aside style={thumbsContainer}>
          {thumbs}
        </aside>        
        <MDBBtn onClick={() => uploadImages(accept)} color={'primary'} style={{marginTop: '1rem', borderRadius: '10px', textTransform: 'initial', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem', width: '100%'}} disabled={!accept ? true : false }><BsCheck2Circle size={'1.1rem'} />&nbsp;&nbsp; Salvar e concluir</MDBBtn>
      </section>
      :
      <>
        { updated !== 'error' ?           
        <MDBRow className='justify-content-center'>    
          <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
            <BsCheck2Circle style={{width: '100px' , height: '100px', color: '#D38039'}} />
            <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Tudo Pronto</h4>
            <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
              <i>As fotos enviadas já estão disponíveis na página do empreendimento.</i></p>
            <MDBBtn onClick={() => window.location.href = '/dashboard/empreendimentos'} style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', borderRadius: '20px', backgroundColor: '#D38039'}}>Acessar empreendimentos</MDBBtn>
          </MDBCol> 
        </MDBRow>
        :
        <MDBRow className='justify-content-center'>    
          <MDBCol style={{paddingTop: '3rem', paddingBottom: '3rem'}} className='text-center'>
            <BiErrorAlt style={{width: '100px' , height: '100px', color: '#D38039'}} />
            <h4 style={{marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600,}}>Houve um erro...</h4>
            <p style={{marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.85rem'}}>
              <i>Houve um erro ao processar as imagens em nosso servidor, tente novamente.</i></p>
            <MDBBtn onClick={() => setUpdated(false)} style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', borderRadius: '20px', backgroundColor: '#D38039'}}>Tentar novamente</MDBBtn>
          </MDBCol> 
        </MDBRow>
        }
      </> 
      }
      </>
      }
      </>
    );
  }


export { GalleryUpdate };