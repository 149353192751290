import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import { EmpreendimentoPreloader } from "../component/preloader";
import NavBar from "../component/navbar";
import Footer from "../component/footer";
import '../index.css';
import { GoVerified } from 'react-icons/go';
import { AiOutlinePicLeft } from 'react-icons/ai';
import { BsFillCameraVideoFill } from 'react-icons/bs';
import { CgTrees } from 'react-icons/cg';
import { GiCrane } from 'react-icons/gi';
import { FaTheaterMasks } from 'react-icons/fa';
import { MdOutlineShareLocation, MdOutlineSupportAgent, MdOtherHouses, MdLocalPolice, MdPhoneCallback } from 'react-icons/md';
import { IoLogoWhatsapp, IoImage } from 'react-icons/io5';
import { SiGooglestreetview } from 'react-icons/si';
import Carousel from "react-multi-carousel";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function Empreendimento() {
    const [data, setData] = useState(null);
    const [fotos, setFotos] = useState([]);
    const [galleryType, setGalleryType] = useState('fotos');
    const { slug_id } = useParams();

    useEffect(() => {
        (async () => {
            const headersList = {
                mode: 'cors',
            }

            const response = await fetch(`https://api.jtloteadora.com.br/empreendimento/${slug_id}`, {
                method: "GET",
                headersList
            });

            const data = await response.json();
            document.title = `${data.NOME_EMPREENDIMENTO} | JT Loteadora e Incorporadora`;
            setData(data);
            setFotos(data.FOTOS.split(';').filter(i => i));
        })();
    }, [slug_id]);

    const renderGallery = () => {
        if (galleryType === 'fotos' && data.FOTOS !== 'none') {
            return (
                <div style={{ backgroundColor: '#dcdcdc' }} className='carousel-custom'>
                    <Carousel swipeable={true} draggable={true} showDots={false} responsive={responsive} infinite={false} autoPlaySpeed={1000} keyBoardControl={true} transitionDuration={3000}>
                        {fotos.map((foto, index) => (
                            <img src={`https://api.jtloteadora.com.br/images/${data.ID}/${foto}`} key={index} alt={foto} height={'320px'} width={'100%'} style={{ marginRight: '0px' }} />
                        ))}
                    </Carousel>
                </div>
            );
        } else if (galleryType === 'youtube') {
            return (
                <MDBContainer style={{ maxWidth: '100%' }}>
                    <MDBRow className="justify-content-center" style={{ backgroundColor: '#dcdcdc' }}>
                        <MDBCol md={6} className='text-center'>
                            <iframe width="100%" height="320px" className='shadow-5' style={{ zIndex: 1000, borderRadius: '15px', marginTop: '1rem', marginBottom: '1rem' }} src={data.YOUTUBE_LINK}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            );
        }
        return null;
    }

    const renderCardBody = () => (
        <div className='ml-2' style={{ marginRight: '1.4rem' }}>
            <h4 style={{ borderLeft: 'solid 0.5rem #D38039', borderRadius: '5px', paddingLeft: '0.7rem', fontWeight: 600, marginBottom: '1.3rem' }}>Construa ou Invista</h4>
            <h6 className='mt-4' style={{ fontWeight: '500' }}>Lotes Exclusivos</h6>
            <p className='mt-2' style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: '1rem' }}>
                {data.LOTES_TOTAIS} lotes feitos com o melhor em planejamento e infraestrutura
            </p>
            <hr />
            {data.LOTES_VENDIDOS.length > 0 && (
                <>
                    <h6 className='mt-2' style={{ fontWeight: '500' }}>Lotes Vendidos</h6>
                    <p className='mt-2' style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: '1rem' }}>
                        {data.LOTES_VENDIDOS} clientes felizes e satisfeitos
                    </p>
                    <hr />
                </>
            )}
            {data.LOTES_VENDIDOS.length > 0 && (
                <>
                    <h6 className='mt-2' style={{ fontWeight: '500' }}>Lotes Esperando Você</h6>
                    <p className='mt-2' style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', marginBottom: '1rem' }}>
                        {(data.LOTES_TOTAIS - data.LOTES_VENDIDOS)} lotes aguardando por você
                    </p>
                    <hr />
                </>
            )}
            {data.VALOR_PARCELAS && data.VALOR_PARCELAS !== '0.00' ? (
                <>
                    <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.8rem' }}>Com parcelas a partir de</p>
                    <h3 style={{ fontWeight: 700, marginTop: '-0.7rem' }}>
                        {parseFloat(data.VALOR_PARCELAS).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </h3> 
                </>) : null
            }
            <MDBBtn onClick={() => window.location.href = `https://api.whatsapp.com/send/?phone=+5514996028016&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20empreendimento ${data.NOME_EMPREENDIMENTO}`} color='success' style={{ borderRadius: '20px', width: '100%', marginTop: '1rem', fontSize: '1rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial' }} outline>
                <IoLogoWhatsapp size={'1.5rem'} />&nbsp; Falar no WhatsApp
            </MDBBtn>
            <div className="separator" style={{ marginTop: '0.7rem', marginBottom: '0.7rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem' }}>ou ligue para nós</div>
            <MDBBtn onClick={() => window.location.href = 'tel:14996028016'} style={{ borderRadius: '20px', width: '100%', fontSize: '1rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial' }} outline>
                <MdPhoneCallback size={'1.5rem'} />&nbsp; (14) 9 9602-8016
            </MDBBtn>
        </div>
    );

    const renderAdvantages = () => {
        const advantages = [
            { condition: data.INFRAESTRUTURA, icon: <GiCrane size={'1.7rem'} color='#D38039' />, title: "Infraestrutura", text: data.INFRAESTRUTURA },
            { condition: data.SEGURANCA, icon: <MdLocalPolice size={'1.7rem'} color='#D38039' />, title: "Segurança", text: data.SEGURANCA },
            { condition: data.LAZER, icon: <FaTheaterMasks size={'1.7rem'} color='#D38039' />, title: "Lazer", text: data.LAZER },
            { condition: data.SUSTENTABILIDADE, icon: <CgTrees size={'1.7rem'} color='#D38039' />, title: "Sustentabilidade", text: data.SUSTENTABILIDADE },
        ];

        return advantages.map((advantage, index) => {
            if (!advantage.condition) return null;

            return (
                <div key={index} className="d-flex align-items-center" style={{ marginBottom: '1.5rem' }}>
                    <div className="shadow-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem' }}>
                        {advantage.icon}
                    </div>
                    <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem', fontSize: '1.1rem' }}>
                        {advantage.title}<br />
                        <span style={{ fontSize: '0.9rem' }}>{advantage.text}</span>
                    </p>
                </div>
            );
        });
    };

    const renderReasons = () => (
        <MDBRow className='justify-content-center' style={{ paddingBottom: '3rem' }}>
            <MDBCol md={6}>
                <h3 style={{ marginTop: '1rem', fontSize: '2.2rem', color: '#2B2F33', fontFamily: 'Gotham, sans-serif', fontWeight: 700, paddingLeft: '0.7rem', borderRadius: '5px', borderLeft: 'solid 0.5rem #D38039' }}>Por que nos escolher?</h3>
                <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify', marginBottom: '2rem', fontSize: '0.9rem', marginTop: '1.5rem' }}>A JT Loteadora representa a junção entre a tradição e o nome de uma família centenária com a inovação e as melhores soluções em termos de loteamento, tanto para investir quanto para morar.</p>
                {renderAdvantages()}
            </MDBCol>
            <MDBCol md={6} className="d-flex justify-content-center">
                <div className='text-center'>
                    <MDBCard style={{ backgroundColor: '#D38039', borderRadius: '15px', height: '200px', width: '100%', marginTop: '15rem' }}>
                        <MDBCardBody>
                            <iframe width="100%" height="270px" className='shadow-5' style={{ zIndex: 1000, borderRadius: '15px', marginTop: '-13rem' }} src="https://www.youtube-nocookie.com/embed/IOs8t6hN4To"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                            <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, color: '#f2f2f2', marginTop: '0.7rem' }}>Clique no botão abaixo e conheça mais sobre nós!</p>
                            <MDBBtn onClick={() => window.location.href = '/nossa-historia'} style={{ borderRadius: '25px', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textTransform: 'initial', fontSize: '1rem' }} color='light'>Conheça nossa história</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </MDBCol>
        </MDBRow>
    );

    return (
        <>
            <NavBar />
            {data ? (
                <>
                    {renderGallery()}
                    <MDBContainer style={{ maxWidth: '90%' }}>
                        <MDBCol md={4} className="card-flutuante mobile-hidden" style={{ paddingTop: '2rem' }}>
                            <MDBCard style={{ borderRadius: '10px', marginTop: '2rem', border: 'solid 0.5px #dcdcdc' }}>
                                <MDBCardBody>
                                    {renderCardBody()}
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBRow>
                            <MDBCol size={12} style={{ paddingTop: '2rem' }}>
                                {(data.FOTOS !== 'none') &&
                                    <MDBBtn onClick={() => setGalleryType('fotos')} style={{ marginRight: '0.5rem', borderColor: '#D38039', color: '#D38039', fontSize: '0.8rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial', borderRadius: '20px' }} outline>
                                        <IoImage color='#D38039' /> Galeria
                                    </MDBBtn>
                                }
                                {data.MAPS_LINK.length > 0 && (
                                    <MDBBtn onClick={() => window.open(data.MAPS_LINK, '_blank')} style={{ marginRight: '0.5rem', borderColor: '#D38039', color: '#D38039', fontSize: '0.8rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial', borderRadius: '20px' }} outline>
                                        <SiGooglestreetview /> Mapa
                                    </MDBBtn>
                                )}
                                {data.YOUTUBE_LINK.length > 0 && (
                                    <MDBBtn onClick={() => setGalleryType('youtube')} style={{ marginRight: '1rem', borderColor: '#D38039', color: '#D38039', fontSize: '0.8rem', fontWeight: 600, fontFamily: 'Gotham, sans-serif', textTransform: 'initial', borderRadius: '20px' }} outline>
                                        <BsFillCameraVideoFill /> Vídeo
                                    </MDBBtn>
                                )}
                            </MDBCol>
                            <MDBCol md={12}>
                                <MDBRow>
                                    <MDBCol>
                                        <h2 style={{ marginTop: '2rem', borderLeft: 'solid 0.5rem #D38039', borderRadius: '5px', paddingLeft: '0.7rem', fontWeight: 600 }}>{data.NOME_EMPREENDIMENTO}</h2>
                                        <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify' }}><MdOutlineShareLocation size={'1.3rem'} />&nbsp; {data.CIDADE_LOCALIZACAO}</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <h5 style={{ marginTop: '1rem', fontWeight: 600 }}><AiOutlinePicLeft size={'1.5rem'} style={{ marginTop: '-0.2rem' }} /> Descrição do empreendimento</h5>
                                        <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, fontSize: '0.9rem', textAlign: 'justify' }}>{data.DESCRICAO}</p>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={12}>
                                        <h3 style={{ borderLeft: 'solid 0.4rem #D38039', borderRadius: '5px', paddingLeft: '0.7rem', marginTop: '2rem', fontWeight: 600 }}>Vantagens</h3>
                                        <p style={{ fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, marginBottom: '2rem' }}>Conheça as vantagens do empreendimento</p>
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        {renderAdvantages()}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={4} className="mobile-view" style={{ paddingTop: '2rem' }}>
                                <MDBCard style={{ borderRadius: '10px', marginTop: '2rem', border: 'solid 0.5px #dcdcdc' }}>
                                    <MDBCardBody>
                                        {renderCardBody()}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    <MDBContainer style={{ maxWidth: '90%' }}>
                        <hr style={{ marginTop: '3rem', marginBottom: '2rem' }} />
                        {renderReasons()}
                    </MDBContainer>
                </>
            ) : (
                <EmpreendimentoPreloader />
            )}
            <Footer />
        </>
    );
}

export default Empreendimento;