import { useEffect } from 'react'; 
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdb-react-ui-kit';
import NavBar from '../component/navbar';
import Footer from '../component/footer';
import { Empreendimentos } from '../component/empreendimentos-list';
import BannerBackground from '../img/3.jpg';
import Message from '../img/message.svg';
import { MdOutlineSupportAgent, MdOtherHouses, MdPhoneInTalk } from 'react-icons/md';
import { GoVerified } from 'react-icons/go';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { BsArrowLeftRight } from 'react-icons/bs';
import '../index.css';

function Index () {

    useEffect(() => {
        document.title = "Inovação, Tradição e Confiança | JT Loteadora e Incorporadora";
        window.scrollTo(0, 0);
        if(document.location.href.substring(document.location.href.lastIndexOf('/') + 1) !== ''){
            document.getElementById(document.location.href.substring(document.location.href.lastIndexOf('#') + 1)).scrollIntoView();
        }
    },[]);

    return (
        <div>
            <NavBar />
            <MDBContainer style={{maxWidth: '90%'}}>
                <MDBRow>
                    <MDBCol>
                        <MDBCard className='shadow-0 home-background-position' id='inicio' style={{ marginTop: '2rem', backgroundImage: `url(${BannerBackground})`, borderRadius: '15px', backgroundSize: 'cover', }}>
                            <MDBCardBody style={{padding: '5rem 1.5rem 5rem 1.5rem', borderRadius: '15px'}} className='home-gradient-background'>
                                <h1 style={{color: '#f2f2f2', fontWeight: 600}}>Encontre o lugar perfeito <br className='mobile-hidden' />para sua família</h1>
                                <h6 style={{color: '#f2f2f2', marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify'}}>Nos comprometemos com a qualidade, desde o planejamento até <br className='mobile-hidden'/> a execução de todos os detalhes da infraestrutura.</h6>
                                <MDBBtn onClick={() => window.location.href = '/#empreendimentos'} style={{marginTop: '2rem', borderRadius: '25px', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textTransform: 'initial', fontSize: '1.05rem'}} color='light'><GoVerified size={'1.3rem'} color='#D38039'/>&nbsp; Saiba mais</MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <hr style={{marginTop: '3rem'}} />
                    </MDBCol>
                </MDBRow>
                <MDBRow id='empreendimentos'>
                    <MDBCol>
                        <h3 style={{marginTop: '1rem', fontSize: '2.2rem', color: '#2B2F33', fontFamily: 'Gotham, sans-serif', fontWeight: 700,  paddingLeft: '0.7rem', borderRadius: '5px', borderLeft: 'solid 0.5rem #D38039'}}>Construa ou Invista</h3>
                        <p style={{fontSize: '0.9rem', marginTop: '1rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify'}}>Procura por um lugar confortável, conveniente e seguro para sua família ou talvez procure um investimento seguro para seu dinheiro? Independente da sua resposta você 
                            acabou de achar o que procura!</p>
                    </MDBCol>
                </MDBRow>
                <Empreendimentos style={{paddingBottom: '1rem', marginTop: '0.7rem', overflowX: 'scroll' , whiteSpace: 'nowrap',}} className='justify-content-center scroll-bar-custom'/>
                <p style={{fontSize: '0.9rem', marginTop: '2rem', color: '#555555', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'center'}}>Arraste para lado e veja nossos empreendimentos <BsArrowLeftRight /> </p>
                <MDBRow>
                    <MDBCol>
                        <hr style={{marginTop: '1rem'}} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center' id='porque-nos-escolher'>
                    <MDBCol md={5}>
                        <h3 style={{marginTop: '1rem', fontSize: '2.2rem', color: '#2B2F33', fontFamily: 'Gotham, sans-serif', fontWeight: 700, paddingLeft: '0.7rem', borderRadius: '5px', borderLeft: 'solid 0.5rem #D38039'}}>Por que nos escolher?</h3>
                        <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify', marginBottom: '2rem', fontSize: '0.9rem', marginTop: '1.5rem'}}>A JT Loteadora representa a junção entre a tradição e o nome de uma família centenária com a inovação e as melhores soluções em termos de loteamento, tanto para investir quanto para morar.</p>
                        <div className="d-flex align-items-center" style={{marginBottom: '1.5rem'}}>
                            <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                                <MdOutlineSupportAgent size={'2rem'} color='#D38039'/>
                            </div>                                
                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}>Suporte pesonalizado e humanizado para você</p>
                        </div>    
                        <div className="d-flex align-items-center" style={{marginBottom: '1.5rem'}}> 
                            <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                               <GoVerified size={'1.5rem'} color='#D38039'/>
                            </div>                               
                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}>Loteamentos e incorporações que ostentam dos mais altos padrões </p>
                        </div> 
                        <div className="d-flex align-items-center">
                            <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                               <MdOtherHouses size={'1.7rem'} color='#D38039'/>
                            </div>                                
                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}>Tradição e respeito no mercado</p>
                        </div>                                   
                    </MDBCol>
                    <MDBCol md={6} className="d-flex justify-content-center">
                        <div className='text-center'>
                            <MDBCard style={{backgroundColor: '#D38039', borderRadius: '15px', height: '200px', width: '100%', marginTop: '15rem'}}>
                                <MDBCardBody>
                                    <iframe width="100%" height="270px" className='shadow-5' style={{zIndex: 1000, borderRadius: '15px', marginTop: '-13rem'}} src="https://www.youtube-nocookie.com/embed/IOs8t6hN4To" 
                                        title="YouTube video player" frameBorder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen>
                                    </iframe>
                                    <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, color: '#f2f2f2', marginTop: '0.7rem'}}>Clique no botão abaixo e conheça mais sobre nós!</p>                                
                                    <MDBBtn onClick={() => window.location.href = '/nossa-historia'} style={{borderRadius: '25px', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textTransform: 'initial', fontSize: '1rem'}} color='light'>Conheça nossa história</MDBBtn>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </MDBCol>                    
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <hr style={{marginTop: '3rem'}} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='justify-content-center' style={{marginBottom: '3rem'}} id='faleconosco'>
                    <MDBCol md={6} className='justify-content-center d-flex align-items-center'>
                        <img src={Message} width='70%' className='img-fluid'/>
                    </MDBCol>
                    <MDBCol md={5}>
                        <h3 style={{marginTop: '1rem', fontSize: '2.2rem', color: '#2B2F33', fontFamily: 'Gotham, sans-serif', fontWeight: 700,  paddingLeft: '1rem', borderRadius: '5px', borderLeft: 'solid 0.5rem #D38039'}}>Gostaria de falar com a gente?</h3>
                        <p style={{fontSize: '0.9rem', marginTop: '2rem', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'justify'}}>
                            Sabemos que um suporte humanizado é muito importante para trazer conforto e segurança para nossos clientes, assim oferecemos 
                            canais de atendimento para que você possa tirar suas dúvidas e falar com a gente sempre que quiser!
                        </p>
                        <div className="d-flex align-items-center" style={{marginBottom: '1.5rem', marginTop: '2rem'}}>
                            <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                                <IoLogoWhatsapp size={'1.5rem'} color='#D38039'/>
                            </div>                                
                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', fontSize: '1.4rem', marginTop: '0.8rem'}}><a style={{color: 'initial'}} href='https://api.whatsapp.com/send/?phone=+5514996028016&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es.' target={'_blank'} ><span style={{fontSize: '0.9rem'}}>(14)</span> 9 9602-8016</a></p>
                        </div>    
                        <div className="d-flex align-items-center" style={{marginBottom: '1.5rem'}}> 
                            <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                               <MdPhoneInTalk size={'1.5rem'} color='#D38039'/>
                            </div>                               
                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', fontSize: '1.4rem', marginTop: '0.8rem'}}><a style={{color: 'initial'}} href='tel:14996028016'><span style={{fontSize: '0.9rem'}}>(14)</span> 9 9602-8016</a></p>
                        </div> 
                        <div className="d-flex align-items-center">
                            <div className="shadow-2 d-flex align-items-center justify-content-center" style={{backgroundColor: '#D3803955', width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.2rem'}}>
                               <FaMapMarkerAlt size={'1.5rem'} color='#D38039'/>
                            </div>                                
                            <p style={{fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, width: '80%', marginTop: '0.8rem'}}><a style={{color: 'initial'}}  target='_blank' href='https://www.google.com/maps/place/Av.+Tiradentes,+360+-+Bairro+S%C3%A3o+Jos%C3%A9,+Santa+Cruz+do+Rio+Pardo+-+SP,+18900-000/@-22.903894,-49.626581,17z/data=!3m1!4b1!4m5!3m4!1s0x94c06a39db17a8c5:0xb658b047606a06ca!8m2!3d-22.903894!4d-49.626581'>
                                Av. Tiradentes, 360 - Sala 64 - Santa Cruz do Rio Pardo | SP</a></p>
                        </div>
                        <p style={{fontSize: '0.9rem', marginTop: '2rem', marginBottom: 0, color: '#555555', fontFamily: 'Gotham Light, sans-serif', fontWeight: 600, textAlign: 'center'}}>Clique na opção desejada para acessar <FiExternalLink /> </p>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </div>
    );
}

export default Index;